<template>
  <v-navigation-drawer app id="drawer"
  :class="{'mobile': $vuetify.breakpoint.smAndDown}"
  color="transparent"
  v-model="drawer"
  mobile-breakpoint="960"
  :temporary="checkTemporary()"
  >
  <div class="d-flex align-center justify-start logo-container">
    <a href="/" class="d-flex align-center logo">
      <img class="logo ml-3" src="@/assets/Youtorial.png">
    </a>
  </div>

  <v-divider v-if="$vuetify.breakpoint.smAndDown || isVideoPage()"></v-divider>

    <v-list nav class="flex-grow-1">
      <v-list-item
        link
        v-for="(item, index) in upperItems" :key="index"
        @click="$router.push({ name: item.path}).catch(e=>{})"
        :class="{'active' : $route.name == item.path}">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>



      <v-divider></v-divider>
      <template v-if="isVideoPage()">
        <v-list-item  class="secondary--text text--darken-2" @click="$router.push({name:'create', params: {videoId: $route.params.videoId}})">
          <v-list-item-title><v-icon color="secondary darken-2" class="mr-1">library_books</v-icon> Edit</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>

      <v-list-item  class="primary--text text--darken-2" @click="$router.push({name: 'build'})">
        <v-list-item-title><v-icon color="secondary darken-1" class="mr-1">video_library</v-icon> Create</v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

        <v-list-item
        v-for="(item, index) in lowerItems" :key="item.path"
        link
        @click="$router.push({ name: item.path}).catch(e=>{})"
        :class="{'active' : $route.name == item.path}">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>

    </v-list>

  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "drawer",
  computed:{
    isAuthenticated: function (){ return this.$store.state.userId},
    ...mapGetters({
      user:'GET_USER',
      userId:'GET_USER_ID',
      currentVideo: 'GET_CURRENT_VIDEO',
      buildVideo: 'GET_BUILD_VIDEO'
    }),
    drawer:{
      get() { return this.$store.state.drawer; },
      set(value) { this.$store.commit('TOGGLE_DRAWER', value) },
    },
  },
  data(){
    return{
      upperItems:[
        {icon: 'home', title: 'Home', path: 'home'},
        {icon: 'info', title: 'About', path: 'about'},
        {icon: 'library_books', title: 'My Youtorials', path: 'myYoutorials'},
      ],
      lowerItems:[
        {icon: 'help', title: 'Help & Feedback', path: 'help'},
      ]
        // drawer: null,
    }
  },
  methods:{
    logOut(){
      this.$store.dispatch('logout');
      this.$router.replace('home')
    },

    isVideoPage(){
      if(this.isAuthenticated && this.user){
        if(!this.$isEmpty(this.buildVideo)){
          return this.buildVideo.userId == this.userId ?  true : false;
        }
        else if(!this.$isEmpty(this.currentVideo)){

          return this.currentVideo.userId == this.userId ?  true : false;
        }
        else{
          return false
        }
      }
    },
    checkTemporary(){
      // console.log(this.isVideoPage(), this.$vuetify.breakpoint.smAndDown);
      return this.isVideoPage() || this.$vuetify.breakpoint.smAndDown;
    }
  },
  mounted(){
    this.checkTemporary()
  }
};
</script>

<style lang="scss">
  #drawer{
    // z-index: 999999;
    .logo-container{
      height: 64px;
    }
    // v-navigation-drawer v-navigation-drawer--fixed v-navigation-drawer--open v-navigation-drawer--temporary theme--light white
    // v-navigation-drawer v-navigation-drawer--fixed v-navigation-drawer--open theme--light transparent
    .v-navigation-drawer__border{
      display: none;
    }
    .v-navigation-drawer__content{
        display: flex;
        flex-direction: column;
    }
    &.v-navigation-drawer--temporary{
      background-color: white!important;
    }
    &.mobile{
        .logo{
          margin-bottom: 8px;
        }
    }
    .logo{
      max-width:150px;
    }
    .v-list{
      font-size: 16px;
      .v-list-item{
        margin: 0;
        &.active{
          // color: var(--v-primary-darken1)!important;
          font-weight: bold;
          // background-color: #ddd;
          .v-list-item__icon .v-icon{
            color: var(--v-primary-darken1)!important;
          }
        }
      }
      .v-list-item__content{
        padding:0;
        .v-list-item__title{
          color: #666;
          line-height: 24px;
        }
      }
      .v-list-item__icon{
        margin: auto 12px auto 0;
        .v-icon{
          color: #aaa;
        }
      }
    }
    .space-tab{
      color: #ccc;

      margin: 0 15px;
    }
  }
</style>
